.App {
  font-size: 15px;
  min-height: 100vh;
  width: 100%;
  background-color: #09171F;
  align-items: center;
  justify-content: center;
  /* color: #CEA17A; */
  text-align: center;
}

body,
html {
  background-color: #09171F;
  height: 100%;
}