.terminal-window {
    background-color: #2D2D2D;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin-top: 0px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.terminal-window.visible {
    opacity: 1;
    visibility: visible;
}

.terminal-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.terminal-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 6px;
    background-color: #808080;
}

.terminal-content {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    padding: 15px;
}

.self-introduction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    /* この値を調整して、ボタンの位置を上下に移動できます */
}

.terminal-window {
    /* 既存のスタイル */
    margin-top: 0;
    /* ここを0に変更 */
}

.self-introduction {
    font-family: monospace, sans-serif !important;
    text-align: left;
    line-height: 1.6;
    font-size: 16px;
    color: #333;
}

.self-introduction p {
    margin: 0;
    padding: 0;
}

.json-key {
    color: rgba(0, 0, 0, 1);
}

.json-string {
    color: rgba(0, 0, 0, 0.9);
}

.json-number {
    color: rgba(0, 0, 0, 0.4);
}

.json-bracket {
    color: rgba(0, 0, 0, 0.2);
}

.terminal-button {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    color: #CEA17A;
    padding: 10px 20px;
    text-align: center;
    font-family: monospace, sans-serif !important;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

.terminal-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
    /* ホバー時の背景色 */
}