.concept-container {
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #CEA17A;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.concept-container.visible {
    opacity: 1;
    transform: translateX(0);
}

.concept-title {
    font-size: 16px;
    letter-spacing: 2px;
    margin-bottom: 15px;
    text-align: center;
}

.concept-description {
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
}