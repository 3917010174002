.header {
    position: fixed;
    top: 0;
    color: #CEA17A;
    font-family: serif;
    z-index: 1000;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    /* background-color: rgba(0, 0, 0, 0.6); */
    /* color: white; */
    height: 90px;
    position: fixed;
    opacity: 0;
    /* 初期状態で透明 */
    animation: fadeInHeader 7s forwards;
}


@keyframes fadeInHeader {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.logo {
    display: flex;
    align-items: center;
    /* margin-left: 0px; */
    margin-right: 200px;
}

.logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #CEA17A;
}

.logo-image {
    width: 60px;
    /* ロゴのサイズを適切に調整してください */
    height: auto;
    margin-top: -10px;
    /* margin-right: -25px; */
    /* 高さも指定して、アスペクト比を維持します */
    /* object-fit: contain; */
    /* 画像のアスペクト比を保持しつつ、指定したサイズに収めます */
}

.logo h3 {
    margin: 0;
    font-size: 1.5rem;
    /* line-height: 60px; */
    /* ロゴの高さと同じにして、垂直方向の中央揃えを確保します */
}

/* リンクの色を白に保持 */
.header a {
    color: #CEA17A;
    text-decoration: none;
    letter-spacing: 0.15em;
}

/* ホバー時のスタイルを追加（オプション） */
.header a:hover {
    text-decoration: underline;
}


nav ul li {
    list-style: none;
    display: inline-block;
    margin-right: 30px;
}



nav ul li a:hover {
    color: #48b4af;
}