.blog-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 280px 60px 20px;
    background-color: #09171F;
    color: #CEA17A;
    font-family: serif;
    /* Added top padding of 320px (300px + 20px) */
}

.blog-title {
    margin-bottom: 600px;
    font-size: 24px;
    font-weight: 100;
    letter-spacing: 4px;
    color: #CEA17A;
    animation: floatUp 1s ease-out forwards;
    opacity: 0;
    transform: translateY(20px);
}

@keyframes floatUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.articles-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 2列のグリッドを維持 */
    gap: 50px;
}

.article-card {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.article-card:hover {
    transform: translateY(-5px);
}

.article-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.article-header {
    text-align: left;
    margin-left: 15px;
}

.article-title {
    font-size: 16px;
    font-weight: 500;
    margin: 25px 15px 0 15px;
    color: #CEA17A;
}

.read-more-button {
    display: inline-block;
    margin: 0 0 15px 15px;
    padding: 5px 15px;
    background-color: #CEA17A;
    color: #09171F;
    font-weight: 200;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.read-more-button:hover {
    background-color: #000000;
}

.article-date {
    font-size: 14px;
    font-weight: 400;
    color: #CEA17A;
    margin: 0 0 30px 15px;
}

@media (max-width: 768px) {
    .blog-page {
        padding: 300px 60px 40px;
        /* Adjusted padding for smaller screens */
    }
}

@media (max-width: 480px) {
    .blog-page {
        padding: 300px 60px 40px;
        /* Further reduced side padding for very small screens */
    }

    .articles-grid {
        grid-template-columns: 1fr;
        /* Switch to single column on very small screens */
    }
}